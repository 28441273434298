import React from "react"
import BackgroundImage from "gatsby-background-image"

const StyledHero = ({ img, children, home }) => {
    const hero = home ? "dynamic" : "static";
    return (
        <BackgroundImage
        id="HeroContainer"
            className={`jumbotron paral ${hero}`}
            fluid={img}
            home={home}
        >
            {children}
        </BackgroundImage>
    )
}

export default StyledHero
